<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Elimina Documento Aziendale</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
            >
              <p>L'eliminazione del documento è permanente, accertarsi di voler continuare.</p>
            </v-col>
            <v-col
              v-if="noDelete"
              cols="12"
            >
              <p>Per eliminare un documento "urgente" è necessario digitare qui sotto la parola ELIMINA</p>
              <v-text-field
                v-model="testWord"
                label="Controllo"
                outlined
                dense
                @input="checkTestWord"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                class="ma-2"
                :disabled="loading"
                @click="closeModal"
              >
                Chiudi
              </v-btn>
              <v-btn
                color="red"
                class="ma-2"
                :disabled="noDelete"
                :loading="loading"
                @click.prevent="destroyDocument"
              >
                Elimina
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'DeleteDocument',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    documentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      noDelete: false,
      testWord: '',
      loading: false,
    }
  },
  watch: {
    dialogVisible(isShow) {
      if (isShow) {
        this.checkPriority()
      }
    },
  },
  mounted() {
    this.checkPriority()
  },
  methods: {
    async destroyDocument() {
      try {
        this.loading = true
        await this.$api.destroyCompanyDocument(this.documentData.id, this.documentData)
        this.$store.dispatch('set_alert', { msg: 'Documento Eliminato con successo', type: 'success' })
        this.$emit('reload-data')
        this.closeModal()
      } catch (e) {
        this.loading = false
        this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    checkPriority() {
      if (this.documentData.priority === 'high') {
        this.noDelete = true
      }
    },
    checkTestWord() {
      if (this.testWord !== 'elimina') {
        this.noDelete = false
      }
    },
    closeModal() {
      this.$emit('close')
      this.noDelete = false
      this.testWord = ''
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
