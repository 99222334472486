import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Elimina Documento Aziendale")])]),_c(VCardText,[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c('p',[_vm._v("L'eliminazione del documento è permanente, accertarsi di voler continuare.")])]),(_vm.noDelete)?_c(VCol,{attrs:{"cols":"12"}},[_c('p',[_vm._v("Per eliminare un documento \"urgente\" è necessario digitare qui sotto la parola ELIMINA")]),_c(VTextField,{attrs:{"label":"Controllo","outlined":"","dense":""},on:{"input":_vm.checkTestWord},model:{value:(_vm.testWord),callback:function ($$v) {_vm.testWord=$$v},expression:"testWord"}})],1):_vm._e(),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"red","disabled":_vm.noDelete,"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.destroyDocument.apply(null, arguments)}}},[_vm._v(" Elimina ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }